import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AccountStatus,
  ApplicationSubCollection,
  ApplicationView,
  Collections,
  createAuditNonce,
  Dispositions,
  Firebase,
  getColor,
  getNextRole,
  PdfDocuments,
  RolesByDisposition,
  SearchableProfileView,
  useApplicationResume,
  useCallable,
  useNotification,
  UserRoles,
} from '@ozark/common';
import {
  CloneDialog,
  ConfirmationDialog,
  ConfirmationDialogField,
  disputableRoles,
  DisputeReasonsDetail,
  isDisputable,
  ResendDisputeEmailsDialog,
  TransferToMerchantConfirmationDialog,
} from '@ozark/common/components';
import LogRocketLogo from '@ozark/common/static/images/logrocket_logo.png';
import {formatDuration, intervalToDuration} from 'date-fns';
import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {useActiveProfiles} from '../../../hooks/useActiveProfiles';
import {useStore} from '../../../store/helpers';
import {BarTitle} from './BarTitle';
import {RiskAssigneeSelector} from './components/RiskAssigneeSelector';
import {DisputeDialog} from './DisputeDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
      borderTop: 'solid 4px',
    },
    heading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0, 0),
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      paddingRight: theme.spacing(1),
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    grow: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(0, 2),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type BarProps = {
  application: ApplicationView;
  locked: boolean;
  loading: boolean;
  setLoading: any;
  showRiskAssignee?: boolean;
};

export const Bar = ({application, locked, loading, setLoading, showRiskAssignee}: BarProps) => {
  const classes = useStyles();
  const {
    setDisposition: callSetDisposition,
    downloadPdf,
    applicationUnlinkFinicity,
  } = useCallable();
  const [transferToMerchantDialogOpen, setTransferToMerchantDialogOpen] = useState<boolean>(false);
  const showNotification = useNotification();
  const history = useHistory();
  const {resumeApplication} = useApplicationResume();

  const {authUser, authProfile, isAllowedToEditApplication, profiles} = useStore();
  const profile = profiles.dictionary[application.uid as string];
  const activeProfiles = useActiveProfiles(profiles);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [disputeReasonsVisible, setDisputeReasonsVisible] = useState(false);
  const [resendDisputeEmailsVisible, setResendDisputeEmailsVisible] = useState(false);
  const [logRocketMenuEl, setLogRocketMenuEl] = React.useState<null | HTMLElement>(null);
  const [moreMenuEl, setMoreMenuEl] = React.useState<null | HTMLElement>(null);
  const [pendingReasonsMenuEl, setPendingReasonsMenuEl] = React.useState<null | HTMLElement>(null);
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);
  const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);
  const [disposition, setDisposition] = useState<Dispositions>(application.disposition);
  const [dispositionNotifyAgent, setDispositionNotifyAgent] = useState<boolean | undefined>(
    application.dispositionNotifyAgent
  );
  const [dispositionNotifyMerchant, setDispositionNotifyMerchant] = useState<boolean | undefined>(
    application.dispositionNotifyMerchant
  );
  const [nextRole, setNextRole] = useState<UserRoles | null>();
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [closeAccountStatusToChange, setCloseAccountStatusToChange] = useState<boolean | null>(
    null
  );
  const [directBoardingAction, setDirectBoardingAction] = useState<
    ((fields: any) => any | Promise<void>) | null
  >(null);
  const [resolveAction, setResolveAction] = useState<((fields: any) => any | Promise<void>) | null>(
    null
  );
  const [unlinkFinicityAction, setUnlinkFinicityAction] = useState<(() => Promise<void>) | null>(
    null
  );
  const [deleted, setDeleted] = React.useState<Boolean>(application.deleted || false);

  const readonly = !isAllowedToEditApplication(application);

  const pendingDispositionChange = application.disposition !== disposition;

  const durationUnassigned = () => {
    const duration = intervalToDuration({
      start: application.dispositionUpdatedAt?.toDate() || application.createdAt.toDate(),
      end: new Date(),
    });
    return (
      formatDuration(duration, {format: ['months', 'weeks', 'days', 'hours', 'minutes']}) ||
      'less than 1 minute'
    );
  };

  const isClaimable =
    application.unassigned &&
    authProfile.data?.workflow?.viewable.includes(application.disposition);

  const isPendingReasonsMenuAvailable =
    disposition === Dispositions.uwPending &&
    (authProfile?.data?.role === UserRoles.admin ||
      authProfile?.data?.role === UserRoles.underwriter ||
      authProfile?.data?.role === UserRoles.sales);

  const handleSetDispositionValue = async (
    newDispositions: Dispositions,
    force: boolean = false
  ) => {
    const changedDisposition = newDispositions;
    if (
      (application.disposition !== changedDisposition || force) &&
      authProfile.data &&
      isDisputable(changedDisposition, authProfile.data.role) &&
      !dialogVisible
    ) {
      setDisposition(changedDisposition);
      setDialogVisible(true);
    } else {
      setDisposition(changedDisposition);
    }
  };

  const handleReSetPendingDisposition = async () => {
    const changedDisposition = Dispositions.uwPending;
    await handleSetDispositionValue(changedDisposition, true);
  };

  const handleReSetAsIncompleteDisposition = async () => {
    const changedDisposition = Dispositions.asIncomplete;
    await handleSetDispositionValue(changedDisposition, true);
  };

  const handleSetDisposition = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedDisposition = event.target.value as Dispositions;
    await handleSetDispositionValue(changedDisposition);
  };

  const handleDispositionChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (locked) return;
    if (!application.complete && application.disposition !== Dispositions.incomplete) return;
    const next = authProfile.data ? getNextRole(authProfile.data.role, disposition) : null;
    if (next) {
      setNextRole(next);
      setMenuEl(event.currentTarget);
    } else {
      setLoading(true);
      await callSetDisposition({applicationId: application.id, disposition});
      setLoading(false);
    }
  };

  const handleOpenUserAssignMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setUserMenuEl(event.currentTarget);
  };

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreMenuEl(event.currentTarget);
  };

  const handleOpenendingReasonsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPendingReasonsMenuEl(event.currentTarget);
  };

  const handleOpenLogRocketMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLogRocketMenuEl(event.currentTarget);
  };

  const handleAssignToMeClick = async () => {
    const currentUid = authUser.data?.uid;
    if (!currentUid) {
      return;
    }
    await handleUserSelect(currentUid)();
  };

  useEffect(() => {
    setDispositionNotifyMerchant(application.dispositionNotifyMerchant);
  }, [application.dispositionNotifyMerchant]);

  useEffect(() => {
    setDispositionNotifyAgent(application.dispositionNotifyAgent);
  }, [application.dispositionNotifyAgent]);

  const handleUserSelect = (uid: string | null) => async () => {
    if (locked) return;
    setLoading(true);
    setMenuEl(null);
    setUserMenuEl(null);
    if (!(application.disposition === disposition && uid === application.uid)) {
      await callSetDisposition({applicationId: application.id, disposition, uid});
    }
    setLoading(false);
  };

  const getDeleteActionName = () => (deleted ? 'restore' : 'delete');

  const handleDeleteApplication = () => async () => {
    try {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const nextIsDeleted = !application.deleted;
      const data = {deleted: nextIsDeleted, auditNonce};

      const snapshot = await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .get();

      if (snapshot.exists) {
        await snapshot.ref.set(data, {merge: true}).then(() => setDeleted(data.deleted));
      }
      history.goBack();
      showNotification(
        'success',
        nextIsDeleted ? `Application has been deleted.` : 'Application has been restored'
      );
      setConfirmationAction(null);
    } catch (err: any) {
      console.error(`failed to ${getDeleteActionName()} document. ${err.toString()}`);
      showNotification('error', `Error deleting application.`);
    } finally {
      setMoreMenuEl(null);
    }
  };

  const handleUnlinkFinicity = () => async () => {
    try {
      const result = await applicationUnlinkFinicity({applicationId: application.id});
      setUnlinkFinicityAction(null);
      if (result.status === 'error') {
        showNotification('error', `Error unlinking application.`);
        console.error(`failed to unlink from finicity. ${JSON.stringify(result.message)}`);
        return;
      }
      showNotification('success', 'Application has been unlinked');
    } catch (err: any) {
      console.error(`failed to unlink from finicity. ${err.toString()}`);
      showNotification('error', `Error unlinking application.`);
    } finally {
      setMoreMenuEl(null);
    }
  };

  const handleDownloadPdf = async () => {
    setLoading(true);
    downloadPdf({
      applicationId: application.id,
      pdfDocument: PdfDocuments.EvolveTSYS,
      fileName: `evolve-${application.id}.pdf`,
    })
      .then((result: any) => {
        var a = document.createElement('a');
        a.href = result.downloadUrl;
        a.setAttribute('target', '_blank');
        a.setAttribute('download', `evolve-${application.id}.pdf`);
        a.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDirectBoarding = async (fields: any) => {
    const {mid} = fields;
    const appsWithMidSnapshot = await Firebase.firestore
      .collection(Collections.applications)
      .where('mid', '==', mid)
      .get();

    if (!appsWithMidSnapshot.empty) {
      showNotification('error', `MID already exists`);
      return;
    }

    try {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const data = {
        mid: fields.mid,
        directBoarding: true,
        disposition: Dispositions.boarded,
        dispositionUpdatedAt: Firebase.FieldValue.now(),
        boardedAt: Firebase.FieldValue.now(),
        auditNonce,
      };

      const snapshot = await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .get();

      if (snapshot.exists) {
        await snapshot.ref.set(data, {merge: true});
      }
      showNotification('success', `Application has been boarded.`);
      setConfirmationAction(null);
    } catch (err: any) {
      console.error(`failed to board application. ${err.toString()}`);
      showNotification('error', `Error direct boarding application.`);
    }
  };

  const handleResolve = async (notes?: string) => {
    try {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const data = {isConditionallyApproved: false, conditionsResolvedAt: new Date(), auditNonce};

      await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .set(data, {merge: true});

      if (notes) {
        await Firebase.firestore
          .collection(Collections.applications)
          .doc(application.id)
          .collection(ApplicationSubCollection.uwRiskNotes)
          .add({message: notes, createdAt: new Date(), uid: authUser.data?.uid});
      }

      showNotification('success', `Conditions have successfully been resolved.`);
      setResolveAction(null);
    } catch (err: any) {
      console.error(`failed to resolve conditions. ${err.toString()}`);
      showNotification('error', `Error resolving conditions`);
    }
  };

  const handleCloseAccount = async (isClosed: boolean) => {
    try {
      setMoreMenuEl(null);

      await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .update({accountStatus: {status: isClosed ? AccountStatus.closed : AccountStatus.active}});

      showNotification(
        'success',
        `The application is successfully ${isClosed ? 'closed' : 'restored'}`
      );
    } catch (err: any) {
      console.error(`Failed to update "isClosed" flag of the application ${err.toString()}`);
      showNotification('error', `Error closing the account`);
    }
  };

  const handleDispositionNotificationChanged = async (
    field: 'dispositionNotifyAgent' | 'dispositionNotifyMerchant',
    value: boolean
  ) => {
    try {
      if (field === 'dispositionNotifyAgent') {
        setDispositionNotifyAgent(value);
      }
      if (field === 'dispositionNotifyMerchant') {
        setDispositionNotifyMerchant(value);
      }

      await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .set({[field]: value}, {merge: true});
    } catch (err: any) {
      console.error(`Failed to update ${field} flag of the application ${err.toString()}`);
    }
  };

  const renderLogRocketMenu = (): ReactNode[] => {
    const menu: ReactNode[] = [];
    application?.logRocketSessionURLCollection?.forEach((item, i) => {
      const url = new URL(item);
      const timestamp = parseInt(url.searchParams.get('t') || '');

      if (timestamp) {
        const date = new Date(timestamp);

        menu.push(
          <MenuItem>
            <a href={item} target="_blank" rel="noreferrer">
              {`${i + 1}. ${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}{' '}
              {`(${date.getHours()}:${date.getMinutes()})`}
            </a>
          </MenuItem>
        );
      }
    });

    return menu;
  };

  const handleRegeneratePdfClick = async () => {
    try {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const data = {pdfGenerationTimestamp: new Date(), auditNonce};

      const snapshot = await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .get();

      if (snapshot.exists) {
        await snapshot.ref.set(data, {merge: true});
      }

      showNotification('success', `PDFs have been queued for regeneration.`);
    } catch (_err) {
      showNotification('error', `Error generating PDFs.`);
    }
  };

  const isUnLinkedFinicityVisible =
    !!application.finicityAccount &&
    (authProfile.data?.role === UserRoles.admin ||
      authProfile.data?.role === UserRoles.support ||
      authProfile.data?.role === UserRoles.operations);

  return (
    <Fragment>
      {dialogVisible && (
        <DisputeDialog
          {...{
            disposition,
            setDialogVisible: setDialogVisible,
            application: application,
            setDisposition: setDisposition,
          }}
        />
      )}
      {disputeReasonsVisible && (
        <DisputeReasonsDetail
          applicationId={application.id}
          disposition={application.disposition}
          setDialogVisible={setDisputeReasonsVisible}
        />
      )}
      {resendDisputeEmailsVisible && (
        <ResendDisputeEmailsDialog
          application={application}
          setDialogVisible={setResendDisputeEmailsVisible}
        />
      )}
      {readonly && (
        <Alert severity="info" className={classes.alert}>
          You currently have <b>read only</b> access to this application.
        </Alert>
      )}
      {application.unassigned && (
        <Alert severity="warning" className={classes.alert}>
          This application has been unassigned for <b>{durationUnassigned()}</b>.
        </Alert>
      )}
      <Paper
        className={classes.paper}
        style={{
          borderTopColor: getColor(
            application.disposition || Dispositions.incomplete,
            application.isClosed,
            application.uwUploadedAt
          ),
        }}
        square
      >
        <div className={classes.heading}>
          <Typography variant="body1" component="div">
            <BarTitle application={application} />
            {application.accountStatus?.status === AccountStatus.closed && (
              <span>
                {' '}
                (Closed{' '}
                <Tooltip title="This account is closed" sx={{verticalAlign: 'middle'}}>
                  <InfoIcon color="info" />
                </Tooltip>
                )
              </span>
            )}
          </Typography>
          <div className={classes.grow}></div>

          <Box sx={{display: 'flex', flexWrap: 'wrap', pl: 2}}>
            <div className={classes.actions}>
              <Typography align="center">
                Agent Notifications
                <Switch
                  checked={dispositionNotifyAgent === undefined || dispositionNotifyAgent}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleDispositionNotificationChanged(
                      'dispositionNotifyAgent',
                      event.target.checked
                    )
                  }
                  color="primary"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              </Typography>
            </div>

            <div className={classes.actions}>
              <Typography align="center">
                Merchant Notifications
                <Switch
                  checked={dispositionNotifyMerchant === undefined || dispositionNotifyMerchant}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleDispositionNotificationChanged(
                      'dispositionNotifyMerchant',
                      event.target.checked
                    )
                  }
                  color="primary"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              </Typography>
            </div>
          </Box>

          <div className={classes.actions}>
            {!locked && application.complete && (
              <Fragment>
                {isPendingReasonsMenuAvailable && (
                  <Button onClick={handleOpenendingReasonsMenu}>Pending Reasons</Button>
                )}
                <Menu
                  id="pending-reasons"
                  anchorEl={pendingReasonsMenuEl}
                  onClose={() => setPendingReasonsMenuEl(null)}
                  keepMounted
                  open={Boolean(pendingReasonsMenuEl)}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => setDisputeReasonsVisible(true)}>
                    <Typography>View Pending Reasons</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setResendDisputeEmailsVisible(true)}>
                    <Typography>Resend Pending Emails</Typography>
                  </MenuItem>
                  {!!authProfile?.data?.role && disputableRoles.includes(authProfile.data.role) && (
                    <MenuItem onClick={handleReSetPendingDisposition}>
                      <Typography>Update Pending Reasons</Typography>
                    </MenuItem>
                  )}
                </Menu>
                {disposition === Dispositions.asIncomplete &&
                  (authProfile?.data?.role === UserRoles.admin ||
                    authProfile?.data?.role === UserRoles.agentSupport) && (
                    <Button onClick={handleReSetAsIncompleteDisposition}>
                      Update Incomplete Reasons
                    </Button>
                  )}
                <TextField
                  value={disposition}
                  onChange={handleSetDisposition}
                  variant="standard"
                  InputProps={{
                    classes: {
                      input: classes.selectInput,
                    },
                    disableUnderline: true,
                  }}
                  disabled={readonly}
                  select
                >
                  {[...(authProfile.data?.workflow?.assignable || [])].sortAndMap((e, i) => (
                    <MenuItem key={`${e}-${i}`} value={e}>
                      <span style={{color: getColor(e)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
                      {e}
                    </MenuItem>
                  ))}
                  {application.disposition === Dispositions.failed && (
                    <MenuItem value={Dispositions.failed}>
                      <span style={{color: getColor(Dispositions.failed)}}>
                        &bull;&bull;&bull;&nbsp;&nbsp;
                      </span>
                      {Dispositions.failed}
                    </MenuItem>
                  )}
                </TextField>
                {pendingDispositionChange && !dialogVisible && (
                  <Fragment>
                    <ArrowRightAltIcon />
                    <Tooltip
                      open={pendingDispositionChange && !Boolean(menuEl)}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      placement="top"
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <Typography variant="caption">
                          Confirm update from {application.disposition} to {disposition}.
                        </Typography>
                      }
                      arrow
                    >
                      <Button
                        onClick={handleDispositionChange}
                        disabled={loading}
                        startIcon={
                          loading || Boolean(menuEl) ? (
                            <CheckBoxIcon color="primary" />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )
                        }
                      >
                        {loading && (
                          <CircularProgress className={classes.buttonProgress} size={24} />
                        )}
                        {disposition}
                      </Button>
                    </Tooltip>
                    {nextRole && (
                      <Menu
                        id="assign-menu"
                        anchorEl={menuEl}
                        onClose={() => setMenuEl(null)}
                        keepMounted
                        open={Boolean(menuEl)}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        transformOrigin={{vertical: -56, horizontal: 'center'}}
                        TransitionComponent={Fade}
                      >
                        <MenuItem value={''}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleUserSelect(null)}
                          >
                            Assign to Group
                          </Button>
                        </MenuItem>
                        <ListSubheader component="div">{nextRole}</ListSubheader>
                        {activeProfiles.byRole[nextRole]?.sortAndMap(
                          (e: SearchableProfileView) => (
                            <MenuItem key={e.uid} value={e.uid} onClick={handleUserSelect(e.uid)}>
                              <ListItemIcon sx={{mr: 1}}>
                                <Avatar src={e.photoUrl} />
                              </ListItemIcon>
                              {e.displayName}
                            </MenuItem>
                          ),
                          e => e.displayName
                        )}

                        {authProfile.data?.role === UserRoles.admin && (
                          <Fragment>
                            <Divider />
                            <MenuItem
                              value={authProfile.data?.uid}
                              onClick={handleUserSelect(authProfile.data?.uid)}
                            >
                              <ListItemIcon sx={{mr: 1}}>
                                <Avatar src={authProfile.data?.photoUrl} />
                              </ListItemIcon>
                              {authProfile.data?.displayName}
                            </MenuItem>
                          </Fragment>
                        )}
                      </Menu>
                    )}
                  </Fragment>
                )}
                <Divider orientation="vertical" className={classes.divider} flexItem />
              </Fragment>
            )}

            {showRiskAssignee && (
              <Box sx={{pr: 1}}>
                <RiskAssigneeSelector
                  applicationId={application.id}
                  riskAssigneeUid={application.riskAssigneeUid}
                />
              </Box>
            )}

            <Chip
              avatar={
                profile ? (
                  <Avatar alt={profile.displayName} src={profile.photoUrl}></Avatar>
                ) : (
                  <Avatar>?</Avatar>
                )
              }
              label={profile ? profile.displayName : 'Unassigned'}
              variant="outlined"
              onClick={
                authProfile.data?.role &&
                [UserRoles.admin, UserRoles.underwriter].includes(authProfile.data.role) &&
                application.disposition !== Dispositions.boarded
                  ? handleOpenUserAssignMenu
                  : undefined
              }
            />
            <Menu
              id="user-menu"
              anchorEl={userMenuEl}
              onClose={() => setUserMenuEl(null)}
              keepMounted
              open={Boolean(userMenuEl)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              TransitionComponent={Fade}
              PaperProps={{
                sx: {
                  overflow: 'visible',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem value={''} onClick={handleUserSelect(null)}>
                <Avatar>?</Avatar> Unassigned
              </MenuItem>
              {RolesByDisposition[application.disposition].sortAndMap(role => [
                activeProfiles.byRole[role] && (
                  <ListSubheader component="div">{role}</ListSubheader>
                ),
                activeProfiles.byRole[role]?.map(
                  (e: SearchableProfileView) => (
                    <MenuItem key={e.uid} value={e.uid} onClick={handleUserSelect(e.uid)}>
                      <Avatar src={e.photoUrl} /> {e.displayName}
                    </MenuItem>
                  ),
                  (e: SearchableProfileView) => e.displayName
                ),
              ])}
            </Menu>
            {!(authProfile.data?.role === UserRoles.admin) && isClaimable && (
              <Fragment>
                <Divider orientation="vertical" className={classes.divider} flexItem />
                <Button variant="contained" color="primary" onClick={handleAssignToMeClick}>
                  Assign to me
                </Button>
              </Fragment>
            )}

            <Fragment>
              <Divider orientation="vertical" className={classes.divider} flexItem />
              <Button onClick={handleOpenMoreMenu} size="large">
                Options
              </Button>
              <Menu
                id="more-menu"
                anchorEl={moreMenuEl}
                onClose={() => setMoreMenuEl(null)}
                keepMounted
                open={Boolean(moreMenuEl)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                TransitionComponent={Fade}
              >
                {application.disposition === Dispositions.boarded &&
                  application.isConditionallyApproved && (
                    <MenuItem
                      onClick={() =>
                        setResolveAction(() => (form: any) => handleResolve(form?.notes))
                      }
                    >
                      <Typography>Resolve Conditions</Typography>
                    </MenuItem>
                  )}
                {!application.complete && (
                  <MenuItem onClick={() => resumeApplication(application.id, application.group.id)}>
                    <Typography>Resume</Typography>
                  </MenuItem>
                )}
                {application.disposition === Dispositions.incomplete && (
                  <MenuItem onClick={() => setTransferToMerchantDialogOpen(true)}>
                    <Typography>Transfer to Merchant</Typography>
                  </MenuItem>
                )}
                {authProfile.data?.role === UserRoles.admin &&
                  application.disposition === Dispositions.uwApproved && (
                    <MenuItem
                      onClick={() =>
                        setDirectBoardingAction(() => (form: any) => handleDirectBoarding(form))
                      }
                    >
                      <Typography>Direct Boarding</Typography>
                    </MenuItem>
                  )}
                {isUnLinkedFinicityVisible && (
                  <MenuItem onClick={() => setUnlinkFinicityAction(() => handleUnlinkFinicity())}>
                    <Typography>Unlink Finicity</Typography>
                  </MenuItem>
                )}
                {application.disposition === Dispositions.boarded &&
                  (authProfile.data?.role === UserRoles.admin ||
                    authProfile.data?.role === UserRoles.agentSupport ||
                    authProfile.data?.role === UserRoles.support) && (
                    <MenuItem
                      onClick={() =>
                        setCloseAccountStatusToChange(
                          application.accountStatus?.status !== AccountStatus.closed
                        )
                      }
                    >
                      <Typography>
                        {application.accountStatus?.status === AccountStatus.closed
                          ? 'Restore'
                          : 'Close'}{' '}
                        Account
                      </Typography>
                    </MenuItem>
                  )}
                {application.disposition !== Dispositions.started &&
                  authProfile.data?.role === UserRoles.admin && (
                    <MenuItem
                      onClick={() => setCloneDialogOpen(true)}
                      disabled={authProfile.data?.role !== UserRoles.admin}
                    >
                      <Typography>Clone Application</Typography>
                    </MenuItem>
                  )}
                <MenuItem onClick={handleDownloadPdf} disabled={loading}>
                  <Typography>Download Evolve MPA</Typography>
                  {loading && <CircularProgressStyled size={24} />}
                </MenuItem>
                {application.disposition !== Dispositions.incomplete &&
                  authProfile.data?.role === UserRoles.admin && (
                    <MenuItem
                      onClick={handleRegeneratePdfClick}
                      disabled={authProfile.data?.role !== UserRoles.admin}
                    >
                      <Typography>Regenerate MPAs</Typography>
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={() => setConfirmationAction(() => handleDeleteApplication())}
                  disabled={authProfile.data?.role !== UserRoles.admin}
                >
                  {!application.deleted ? (
                    <Typography>Delete</Typography>
                  ) : (
                    <Typography>Restore</Typography>
                  )}
                </MenuItem>
              </Menu>
            </Fragment>
            {authProfile.data?.role === UserRoles.admin &&
              application.logRocketSessionURLCollection?.length && (
                <Fragment>
                  <IconButton onClick={handleOpenLogRocketMenu} size="large">
                    <img
                      src={LogRocketLogo}
                      width="28"
                      alt="LogRocket screen recording"
                      title="LogRocket screen recording"
                    />
                  </IconButton>
                  <Menu
                    id="logrocket-menu"
                    open={Boolean(logRocketMenuEl)}
                    anchorEl={logRocketMenuEl}
                    onClose={() => setLogRocketMenuEl(null)}
                  >
                    {renderLogRocketMenu()}
                  </Menu>
                </Fragment>
              )}
          </div>
        </div>
      </Paper>
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you want to ${getDeleteActionName()}?`}
        onClose={() => setConfirmationAction(null)}
        onConfirm={confirmationAction}
      />
      {transferToMerchantDialogOpen && (
        <TransferToMerchantConfirmationDialog
          application={application}
          onClose={() => setTransferToMerchantDialogOpen(false)}
          errorMessage="The following fields are required to transfer: Rates, Business Category, Business Type and Processing Type"
        />
      )}
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you'd like to resolve conditions?`}
        fields={[new ConfirmationDialogField('notes', 'Notes', '', null)]}
        onClose={() => setResolveAction(null)}
        onConfirm={resolveAction}
      />
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you'd like to ${
          closeAccountStatusToChange ? 'close' : 'restore'
        } the account?`}
        onClose={() => setCloseAccountStatusToChange(null)}
        onConfirm={
          closeAccountStatusToChange !== null
            ? () => handleCloseAccount(closeAccountStatusToChange)
            : null
        }
      />
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you'd like unlink Finicity?`}
        onClose={() => setUnlinkFinicityAction(null)}
        onConfirm={unlinkFinicityAction}
      />
      <ConfirmationDialog
        title="Direct Boarding Confirmation"
        message={`Enter the assigned MID to board this application directly.`}
        fields={[new ConfirmationDialogField('mid', 'MID', '', null)]}
        onClose={() => setDirectBoardingAction(null)}
        onConfirm={directBoardingAction}
      />
      {authUser.data && cloneDialogOpen && (
        <CloneDialog
          route={ROUTES.APPLICATIONS}
          applicationId={application.id}
          onClose={() => setCloneDialogOpen(false)}
        />
      )}
    </Fragment>
  );
};

const CircularProgressStyled = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});
